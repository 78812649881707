.backbutton {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;

  .wrap {
    display: inline-block;
  }

  &.no-wrap {
    max-width: unset;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;

    a,
    button {
      margin: 0;
    }
  }

  a,
  button {
    font-size: 16px;
    text-decoration: none !important;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;

    &:hover {
      div {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    div {
      transition-duration: 150ms;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 4px 2px 0;
    }

    span {
      margin-left: 8px;
      font-weight: 600;
      font-family: $body-font-family;
      line-height: 1;
      color: $black;
    }
  }
}
