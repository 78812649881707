.page {
  background-color: $white;
  min-height: calc(100% - 56px);
  padding-bottom: 16px;

  .page-title {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px 16px 0 16px;
    position: relative;
    margin-bottom: 32px;

    &.no-padding {
      padding: 0;

      &:after {
        content: '';
        height: 1px;
        width: 100%;
        left: 0;
        bottom: -16px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 18px;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    &:after {
      content: '';
      height: 1px;
      width: calc(100% - 32px);
      left: 16px;
      bottom: -16px;
      background-color: $green;
      position: absolute;
    }
  }
}
