body {
  font-family: $body-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span {
  &.red {
    color: $red !important;
  }

  &.orange {
    color: $orange !important;
  }

  &.small {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $title-font-family;
  font-weight: 600;
  color: $title-font-color;
  margin-top: 16px;
  margin-bottom: 16px;
}

h1 {
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

h2 {
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

h3 {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

h4 {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

h5 {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

p {
  font-family: $body-font-family;
  font-size: $body-font-size-small;
  font-weight: normal;
  color: $body-font-color;

  @media (min-width: 768px) {
    font-size: $body-font-size-medium;
  }

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }
}

ul {
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}

li {
  font-family: $body-font-family;
  font-size: $body-font-size-small;
  font-weight: normal;
  color: $body-font-color;

  @media (min-width: 768px) {
    font-size: $body-font-size-medium;
  }
}

a {
  font-family: $body-font-family;
  font-weight: normal;
  color: $body-font-color;

  @media (min-width: 768px) {
    font-size: $body-font-size-medium;
  }

  &.disabled {
    pointer-events: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    li {
      opacity: 0.6;
    }

    svg {
      opacity: 0.6;
    }
  }
}
