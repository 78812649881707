// Fonts
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: url('../assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: url('../assets/fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: url('../assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: url('../assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
}

// Settings
$black: #000000;
$white: #ffffff;
$grey: #b3b1b2;
$light-grey: #e9e8e7;
$dark-grey: #4a5568;
$dark-blue: #015dac;
$darker-blue: #01447e;
$darkest-blue: #001f39;
$orange: #e58b27;
$red: #c1272d;
$green: #61bf1a;
$dark-green: #065f2e;
$bright-forest: #09984a;
$apple-green: #93c01f;
$apple-green-tint: #e7f5c1;
$purple: #93278f;
$default-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
$title-font-family: 'Open Sans', sans-serif;
$body-font-family: 'Open Sans', sans-serif;
$title-font-color: $bright-forest;
$body-font-color: $black;
$body-font-size-small: 16px;
$body-font-size-medium: 16px;
$title-margin-bottom: 16px;

// Components
@import './components/typography.scss';
@import './components/app.scss';
@import './components/header.scss';
@import './components/slices.scss';
@import './components/login.scss';
@import './components/button.scss';
@import './components/form.scss';
@import './components/page.scss';
@import './components/backbutton.scss';
@import './components/search.scss';
@import './components/toast.scss';
@import './components/chart.scss';
@import './components/cookie-consent.scss';
