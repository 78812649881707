.chart {
  position: relative;
  padding-top: 32px;

  .stamplimit-alert {
    position: absolute;
    top: 0;
    margin: 0;
  }

  .legend {
    display: flex;
    flex-flow: row wrap;
    margin-left: 32px;
    margin-top: 16px;
    justify-content: center;

    p {
      margin-right: 32px;
      position: relative;
      font-size: 12px;
      margin-top: 0;

      &:last-of-type {
        margin-right: 0;
      }

      &:before {
        content: '';
        position: absolute;
        width: 16px;
        top: 50%;
        transform: translateY(-50%);
        left: -22px;
      }

      &.stamplimit {
        &:before {
          border-bottom: 3px dashed brown;
        }
      }

      &.cutting-after-target {
        &:before {
          border-bottom: 3px solid $bright-forest;
        }
      }

      &.cutting-after-limit {
        &:before {
          border-bottom: 3px solid $apple-green;
        }
      }

      &.ceiling {
        &:before {
          border-bottom: 3px solid $red;
        }
      }

      &.ceiling-es {
        &:before {
          border-bottom: 3px solid $purple;
        }
      }
    }
  }

  canvas {
    @media (min-width: 768px) {
      cursor: pointer;
    }
  }
}
