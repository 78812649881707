.slices-container {
  margin-bottom: 16px;
  background-color: $white;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;

  &.home {
    padding-top: 16px;
  }
}

.nav-slice {
  padding: 0 !important;

  h2 {
    margin-bottom: 0;
    margin-top: 32px;
  }

  a {
    text-decoration: none;
  }

  li {
    padding: 0;

    .MuiListItem-root {
      padding: 16px 0 16px 2px;
      transition-duration: 150ms;

      .MuiListItemIcon-root {
        width: 32px;
        height: 32px;
        margin-right: -8px;

        img {
          height: 100%;
          max-height: 100%;
          width: auto;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .MuiListItemSecondaryAction-root {
      right: 0;
    }

    svg {
      fill: $dark-blue;
      transform: translateY(2px);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      color: $body-font-color;
    }

    .delete {
      z-index: 11111;
      position: absolute;
    }
  }
}

.slice:not(.nav-slice) + .nav-slice {
  border-top: 1px solid $green !important;
}

.accordion-slice {
  box-shadow: none !important;
  border-radius: 0;
  margin-bottom: 16px;

  &:before {
    content: none !important;
  }

  &.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    min-height: 64px;
    padding: 0 16px;
    background-color: $apple-green-tint;
    transition-duration: 150ms;

    &:hover {
      background-color: rgba($apple-green, 0.4);
    }

    &.Mui-expanded {
      min-height: 64px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: $body-font-color;
    }

    svg {
      fill: $dark-blue;
    }
  }

  .MuiAccordionSummary-content {
    margin: 16px 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    padding: 16px 16px 0 16px;

    p {
      margin-top: 0;
    }

    img {
      margin-bottom: 16px;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.image-slice {
  margin-top: 16px;
  margin-bottom: 16px;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.favorites-slice {
  padding: 0 !important;

  .wrap {
    position: relative;
    transition-duration: 150ms;
    padding: 16px 44px 16px 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    a {
      width: 100%;
      text-decoration: none;
    }

    .item-wrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title-wrap {
      display: flex;
      flex-direction: column;

      p {
        font-size: 12px;
        margin: 0;
        color: $dark-grey;
      }
    }

    .delete {
      position: absolute;
      top: 52%;
      right: 0;
      transform: translateY(-50%);

      button {
        right: 2px;
      }

      svg {
        font-size: 20px;
        color: $red;
      }
    }

    .MuiListItemSecondaryAction-root {
      right: 8px;
    }

    hr {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
    }

    li {
      padding: 0;

      .MuiListItem-root {
        padding: 16px 0 16px 2px;
      }

      svg {
        fill: $dark-blue;
        transform: translateY(2px);
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: $body-font-color;
      }
    }
  }
}

.annual-clock-slice {
  padding: 16px;
  overflow-x: scroll;

  .info {
    display: inline-block;
    padding: 4px;

    p {
      margin: 0;
      position: relative;
      padding-left: 16px;

      &.recommended-info {
        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $green;
          transform: translateY(-50%);
        }
      }

      &.demand-info {
        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $dark-blue;
          transform: translateY(-50%);
        }
      }
    }
  }

  .recommended {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $green;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .demand {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $dark-blue;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  table {
    thead {
      tr {
        th,
        td {
          text-align: left;
          padding: 4px;

          &.month {
            p {
              text-align: center;
              font-weight: bold;
            }
          }

          &.link {
            padding-right: 16px;
          }

          p,
          a {
            margin: 0;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.html-slice {
  margin-bottom: 16px;

  table {
    width: 100%;

    tbody {
      tr {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        th {
          font-weight: 600;
          font-size: 16px;
          padding: 0 16px;
          text-align: left;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        td {
          text-align: left;
          font-weight: 400;
          font-size: 16px;
          padding: 0 16px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}
