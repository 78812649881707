html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $white !important;
}

.app {
  background-color: $white;
  position: relative;
}

.MuiDivider-root {
  background-color: $green !important;
}

.padding {
  padding: 16px;
}

.padding-sides {
  padding: 0 16px;
}

.padding-top {
  padding-top: 16px;
}

.padding-bottom {
  padding-bottom: 16px;
}

.margin-top {
  margin-top: 16px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.no-margin {
  margin: 0 !important;
}

.loading {
  position: fixed;
  background-color: $light-grey;
  z-index: 111;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  .loading-icon {
    svg {
      color: $bright-forest;
    }
  }

  .loading-text {
    margin-bottom: 0;
  }
}

.Toolbar {
  display: none !important;
}

.hr {
  position: relative;
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: $grey;
}

.truncate-wrap {
  width: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
