.button {
  $self: &;
  position: relative;
  font-family: $body-font-family;

  .button__inner {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.link {
    font-family: $body-font-family;
    font-weight: 600;
    color: $bright-forest;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    position: relative;
    display: block;
    text-decoration: none;
    font-size: $body-font-size-small;

    &:hover {
      color: $dark-green;
    }

    @media (min-width: 768px) {
      font-size: $body-font-size-medium;
    }
  }

  &.default {
    position: relative;
    border: 1px solid $bright-forest;
    background-color: $bright-forest;
    padding: 10px 32px;
    cursor: pointer;
    color: $white;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    font-size: 18px;
    transition: all 0.25s ease-in-out;

    svg {
      position: absolute;
      left: 32px;
      transition: all 0.25s ease-in-out;

      path {
        fill: $white;
      }
    }

    &:hover:not(:disabled) {
      color: $white;
      border-color: $dark-green;
      background-color: $dark-green;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &.small {
      font-size: 14px;
      padding: 8px 24px;
    }

    &:disabled,
    &.disabled {
      color: $white;
      border: 1px solid $grey;
      background-color: $grey;
      cursor: not-allowed;

      svg {
        path {
          fill: $grey;
        }
      }

      &:hover {
        background-color: $grey !important;
        color: $white !important;
        border: 1px solid $grey !important;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.hollow {
    position: relative;
    border: 1px solid $dark-blue;
    background-color: $white;
    padding: 10px 32px;
    cursor: pointer;
    color: $dark-blue;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    font-size: 18px;
    transition: all 0.35s ease-in-out;
    font-size: 16px;

    svg {
      position: absolute;
      left: 32px;
      transition: all 0.35s ease-in-out;

      path {
        fill: $dark-blue;
      }
    }

    &:hover:not(:disabled) {
      color: $darker-blue;
      border-color: $darker-blue;

      svg {
        path {
          fill: $darker-blue;
        }
      }
    }

    &:disabled {
      color: $grey;
      border: 1px solid $grey;
      cursor: default;

      svg {
        path {
          fill: $grey;
        }
      }
    }
  }

  &.option {
    width: 100%;
  }

  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.transparent {
    background-color: transparent;
    border: none;
    padding: 10px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $black;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.35s ease-in-out;
    font-weight: 600;

    &:hover {
      color: $darker-blue;

      svg {
        transform: translateY(-1px) translateX(-16px);

        path {
          fill: $darker-blue;
        }
      }
    }

    &.small {
      font-size: 14px;
      padding: 8px 24px;
    }

    svg {
      transform: translateY(-1px) translateX(-8px);
      transition: all 0.35s ease-in-out;
    }

    &.language {
      font-weight: normal;

      &:disabled {
        font-weight: 600;
        cursor: default;
      }
    }
  }
}

.MuiIconButton-root {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
