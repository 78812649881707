.login {
  width: 100%;
  height: 100%;

  .login-wrap {
    display: flex;
    position: relative;
    flex: 1;
    float: none;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 32px;
    padding-top: 32px;
    min-height: 100%;
    width: 100%;
    background-color: $light-grey;

    .version-number {
      margin: 0;
      text-align: center;
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      color: $grey;
      font-size: 12px;
    }

    .login-box {
      padding: 32px 16px;
      width: 380px;
      max-width: calc(100% - 32px);
      border-radius: 9px;
      box-shadow: $default-shadow;
      margin-left: auto;
      margin-right: auto;

      .logo-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }

      .logo-wrap-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 32px 0 0px 0;
      }

      .title-wrap {
        margin-top: 0;
        text-align: center;
        margin-bottom: 32px;

        &.no-margin {
          margin-bottom: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 0;
        }

        p {
          margin-top: 16px;
          margin-bottom: 0;
        }
      }

      button[type='submit'] {
        margin-top: 32px;
      }

      .backbutton {
        margin-bottom: 32px;
      }

      .register-text {
        text-align: center;
        margin-top: 32px;

        p {
          margin-bottom: 0;
        }

        button,
        a {
          margin-top: 8px !important;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
