.toast {
  position: fixed;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%) translateY(8px);
  bottom: 16px;
  z-index: 11111;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  width: calc(100% - 32px);
  max-width: 768px;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transform: translateX(-50%) translateY(0px);
  }

  .wrap {
    border-radius: 9px;
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 16px;
    background-color: $white;
    position: relative;
    box-shadow: $default-shadow;
    display: flex;
    flex-direction: row;

    .content {
      width: 100%;
      padding-top: 16px;
      padding-right: 8px;
    }

    .close {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        padding: 8px;
        margin: 8px 8px 8px 16px;
      }
    }

    &.red {
      background-color: $red;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a,
      li {
        color: $white;
      }
    }

    &.green {
      background-color: $bright-forest;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a,
      li {
        color: $white;
      }
    }

    &.orange {
      background-color: $orange;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a,
      li {
        color: $white;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li {
      margin: 0;
      padding-bottom: 16px;
    }
  }
}
