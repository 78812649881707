.form-row {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: flex-end;

  &.result {
    background-color: $light-grey;
    padding: 16px;

    .form-group {
      flex: 1;

      &:first-child {
        &:last-child {
          margin: 0 !important;
        }
      }
    }

    .form-label {
      font-weight: 600;
    }

    p {
      font-size: 20px;
      font-weight: 600;
      color: $bright-forest !important;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .form-checkbox {
    margin-bottom: 8px;

    &:first-of-type {
      margin-right: 16px;
    }

    &:last-of-type {
      margin-left: 16px;
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 0;

    &:first-of-type {
      margin-right: 16px;
    }

    &:last-of-type {
      margin-left: 16px;
    }
  }
}

.clear {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: 4px;
  z-index: 11;
  margin: 0 !important;
  cursor: pointer;
  user-select: none;
}

.eye {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 42px;
  bottom: 8px;
  z-index: 11;
  margin: 0 !important;
  cursor: pointer;
  user-select: none;
}

.form-group {
  position: relative;
  margin-bottom: 16px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.rounded {
    .form-label {
      & + .form-input {
        margin-top: 8px !important;
      }
    }

    .form-input {
      border: 1px solid $apple-green;
      border-radius: 9px;
      position: relative;
      padding: 14px 36px 14px 8px;

      &.counter-input {
        padding-right: 14px;
        margin-top: 8px !important;
        width: calc(100% - 64px);
      }
    }

    .eye {
      bottom: 11px;
    }

    .clear {
      bottom: 8px;
      right: 4px;
    }

    button {
      width: 32px;
      height: 32px;
      border: none;
      background-color: transparent;
      padding: 4px;
      cursor: pointer;
      border-radius: 100%;
      transition-duration: 150ms;
      transform: translateY(3px);

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.form-input-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

select {
  cursor: pointer;
}

.form-input {
  border: none;
  border-bottom: 1px solid $green;
  margin: 0;
  padding: 12px 0 8px 0;
  font-size: $body-font-size-medium;
  font-family: $body-font-family;
  padding-right: 22px;
  background-color: transparent;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:disabled {
    background-color: transparent;
    color: rgba($black, 0.4);
    cursor: not-allowed;
  }

  &::placeholder {
    color: $black;
  }

  &.password {
    padding-right: 68px !important;
  }
}

.select-icon {
  position: absolute;
  bottom: 12px;
  right: 16px;
}

.form-label {
  font-family: $body-font-family;
  font-size: 14px;
  font-weight: normal;
  color: $body-font-color;
  user-select: none;
  position: relative;
  display: block;
}

.form-checkbox {
  position: relative;
  display: flex;
  margin-bottom: 16px;

  label {
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    padding-left: 28px;
    z-index: 111;
  }

  input {
    position: absolute;
    left: 0;
    margin: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    cursor: pointer;

    &[type='radio'] {
      & + span {
        &:before {
          border-radius: 100%;
        }
      }
    }

    & + span {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border: 1px solid $green;
        border-radius: 4px;
        background-color: $white;
      }

      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 9px;
        left: 7px;
        top: calc(50% - 2px);
        transform: translateY(-50%) rotate(45deg);
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
      }
    }

    &:checked {
      & + span {
        &:before {
          background-color: $bright-forest;
        }
      }
    }
  }
}

.counter-slider {
  height: 100%;

  .MuiSlider-root {
    color: $bright-forest !important;

    .MuiSlider-thumb.Mui-focusVisible,
    .MuiSlider-thumb:hover {
      box-shadow: 0px 0px 0px 8px $light-grey;
    }

    .MuiSlider-track {
      height: 3.4px !important;
    }

    .MuiSlider-rail {
      height: 2.4px !important;
    }

    .MuiSlider-thumb {
      height: 15.65px !important;
      width: 15.65px !important;
      margin-top: -7px;
    }
  }

  .slider-wrap {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .slider-value {
      padding: 14px 16px 14px 0px;
      margin: 0;
    }
  }
}

.MuiTabs-root {
  margin-bottom: 16px;

  .MuiTab-root {
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $light-grey;
    }

    .MuiTab-wrapper {
      font-weight: 600;
      text-transform: none;
      color: $black;
      font-size: 16px;
    }

    &.Mui-selected {
      .MuiTab-wrapper {
        color: $bright-forest;
      }
    }
  }

  .MuiTabs-indicator {
    background-color: $apple-green;
  }
}
