.header {
  box-shadow: none !important;
  background-color: transparent !important;

  .network-notice {
    background-color: $orange;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    transition: all 0.15s ease-in-out;
    max-height: 0;

    &.is-active {
      max-height: 100%;
      padding: 4px 16px;
    }

    svg {
      margin-right: 8px;
    }

    p {
      font-size: 12px;
      margin: 0;
    }
  }

  .MuiToolbar-root {
    background-color: $bright-forest;
    min-height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (min-width: 600px) {
      min-height: 56px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .header-left {
      a {
        transform: translateY(3px);
      }
    }

    .header-left,
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a {
      text-decoration: none;
    }

    h1 {
      font-size: 18px;
      color: $dark-blue;
      font-weight: bold;
    }

    .MuiIconButton-root {
      padding: 8px;
    }
  }
}

.menu {
  .MuiPaper-root {
    border-radius: 9px !important;
    box-shadow: $default-shadow !important;
  }

  ul {
    li {
      color: $body-font-color !important;
      font-size: $body-font-size-small !important;

      svg {
        color: $purple;
      }
    }
  }
}
