.CookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1111111111;
  background-color: $white;
  border-top: 2px solid $bright-forest;
  padding-bottom: 16px;

  div {
    &:first-of-type {
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 16px;
    }
    &:last-of-type {
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  button {
    display: inline-block !important;
  }
}
