.search {
  .input-wrap {
    padding: 16px 16px 0 16px;
    max-width: 768px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .results-count {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px 16px 0 16px;
    position: relative;
    margin-bottom: 32px;

    h1 {
      font-size: 18px;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }

    &:after {
      content: '';
      height: 1px;
      width: calc(100% - 32px);
      left: 16px;
      bottom: -16px;
      background-color: $green;
      position: absolute;
    }
  }
}
