@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("../assets/fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  src: url("../assets/fonts/open-sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("../assets/fonts/open-sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  src: url("../assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
}
body {
  font-family: "Open Sans", sans-serif;
}

h1.red,
h2.red,
h3.red,
h4.red,
h5.red,
h6.red,
p.red,
li.red,
a.red,
span.red {
  color: #c1272d !important;
}
h1.orange,
h2.orange,
h3.orange,
h4.orange,
h5.orange,
h6.orange,
p.orange,
li.orange,
a.orange,
span.orange {
  color: #e58b27 !important;
}
h1.small,
h2.small,
h3.small,
h4.small,
h5.small,
h6.small,
p.small,
li.small,
a.small,
span.small {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #09984a;
  margin-top: 16px;
  margin-bottom: 16px;
}

h1 {
  font-size: 18px;
}
@media (min-width: 768px) {
  h1 {
    font-size: 18px;
  }
}

h2 {
  font-size: 16px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 16px;
  }
}

h3 {
  font-size: 14px;
}
@media (min-width: 768px) {
  h3 {
    font-size: 14px;
  }
}

h4 {
  font-size: 14px;
}
@media (min-width: 768px) {
  h4 {
    font-size: 14px;
  }
}

h5 {
  font-size: 14px;
}
@media (min-width: 768px) {
  h5 {
    font-size: 14px;
  }
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}
p.red {
  color: #c1272d;
}
p.green {
  color: #61bf1a;
}

ul {
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}

li {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
@media (min-width: 768px) {
  li {
    font-size: 16px;
  }
}

a {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: #000000;
}
@media (min-width: 768px) {
  a {
    font-size: 16px;
  }
}
a.disabled {
  pointer-events: none;
}
a.disabled h1,
a.disabled h2,
a.disabled h3,
a.disabled h4,
a.disabled h5,
a.disabled p,
a.disabled li {
  opacity: 0.6;
}
a.disabled svg {
  opacity: 0.6;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #ffffff !important;
}

.app {
  background-color: #ffffff;
  position: relative;
}

.MuiDivider-root {
  background-color: #61bf1a !important;
}

.padding {
  padding: 16px;
}

.padding-sides {
  padding: 0 16px;
}

.padding-top {
  padding-top: 16px;
}

.padding-bottom {
  padding-bottom: 16px;
}

.margin-top {
  margin-top: 16px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.no-margin {
  margin: 0 !important;
}

.loading {
  position: fixed;
  background-color: #e9e8e7;
  z-index: 111;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading.is-active {
  opacity: 1;
  pointer-events: all;
}
.loading .loading-icon svg {
  color: #09984a;
}
.loading .loading-text {
  margin-bottom: 0;
}

.Toolbar {
  display: none !important;
}

.hr {
  position: relative;
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: #b3b1b2;
}

.truncate-wrap {
  width: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  box-shadow: none !important;
  background-color: transparent !important;
}
.header .network-notice {
  background-color: #e58b27;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  max-height: 0;
}
.header .network-notice.is-active {
  max-height: 100%;
  padding: 4px 16px;
}
.header .network-notice svg {
  margin-right: 8px;
}
.header .network-notice p {
  font-size: 12px;
  margin: 0;
}
.header .MuiToolbar-root {
  background-color: #09984a;
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (min-width: 600px) {
  .header .MuiToolbar-root {
    min-height: 56px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.header .MuiToolbar-root .header-left a {
  transform: translateY(3px);
}
.header .MuiToolbar-root .header-left,
.header .MuiToolbar-root .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .MuiToolbar-root a {
  text-decoration: none;
}
.header .MuiToolbar-root h1 {
  font-size: 18px;
  color: #015dac;
  font-weight: bold;
}
.header .MuiToolbar-root .MuiIconButton-root {
  padding: 8px;
}

.menu .MuiPaper-root {
  border-radius: 9px !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15) !important;
}
.menu ul li {
  color: #000000 !important;
  font-size: 16px !important;
}
.menu ul li svg {
  color: #93278f;
}

.slices-container {
  margin-bottom: 16px;
  background-color: #ffffff;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}
.slices-container.home {
  padding-top: 16px;
}

.nav-slice {
  padding: 0 !important;
}
.nav-slice h2 {
  margin-bottom: 0;
  margin-top: 32px;
}
.nav-slice a {
  text-decoration: none;
}
.nav-slice li {
  padding: 0;
}
.nav-slice li .MuiListItem-root {
  padding: 16px 0 16px 2px;
  transition-duration: 150ms;
}
.nav-slice li .MuiListItem-root .MuiListItemIcon-root {
  width: 32px;
  height: 32px;
  margin-right: -8px;
}
.nav-slice li .MuiListItem-root .MuiListItemIcon-root img {
  height: 100%;
  max-height: 100%;
  width: auto;
}
.nav-slice li .MuiListItem-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.nav-slice li .MuiListItemSecondaryAction-root {
  right: 0;
}
.nav-slice li svg {
  fill: #015dac;
  transform: translateY(2px);
}
.nav-slice li h1,
.nav-slice li h2,
.nav-slice li h3,
.nav-slice li h4,
.nav-slice li h5,
.nav-slice li h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.nav-slice li .delete {
  z-index: 11111;
  position: absolute;
}

.slice:not(.nav-slice) + .nav-slice {
  border-top: 1px solid #61bf1a !important;
}

.accordion-slice {
  box-shadow: none !important;
  border-radius: 0;
  margin-bottom: 16px;
}
.accordion-slice:before {
  content: none !important;
}
.accordion-slice.Mui-expanded {
  margin: 0 !important;
}
.accordion-slice .MuiAccordionSummary-root {
  min-height: 64px;
  padding: 0 16px;
  background-color: #e7f5c1;
  transition-duration: 150ms;
}
.accordion-slice .MuiAccordionSummary-root:hover {
  background-color: rgba(147, 192, 31, 0.4);
}
.accordion-slice .MuiAccordionSummary-root.Mui-expanded {
  min-height: 64px;
}
.accordion-slice .MuiAccordionSummary-root h1,
.accordion-slice .MuiAccordionSummary-root h2,
.accordion-slice .MuiAccordionSummary-root h3,
.accordion-slice .MuiAccordionSummary-root h4,
.accordion-slice .MuiAccordionSummary-root h5,
.accordion-slice .MuiAccordionSummary-root h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.accordion-slice .MuiAccordionSummary-root svg {
  fill: #015dac;
}
.accordion-slice .MuiAccordionSummary-content {
  margin: 16px 0;
}
.accordion-slice .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.accordion-slice .MuiAccordionDetails-root {
  padding: 16px 16px 0 16px;
}
.accordion-slice .MuiAccordionDetails-root p {
  margin-top: 0;
}
.accordion-slice .MuiAccordionDetails-root img {
  margin-bottom: 16px;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.image-slice {
  margin-top: 16px;
  margin-bottom: 16px;
}
.image-slice img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.favorites-slice {
  padding: 0 !important;
}
.favorites-slice .wrap {
  position: relative;
  transition-duration: 150ms;
  padding: 16px 44px 16px 0;
}
.favorites-slice .wrap:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.favorites-slice .wrap a {
  width: 100%;
  text-decoration: none;
}
.favorites-slice .wrap .item-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.favorites-slice .wrap .title-wrap {
  display: flex;
  flex-direction: column;
}
.favorites-slice .wrap .title-wrap p {
  font-size: 12px;
  margin: 0;
  color: #4a5568;
}
.favorites-slice .wrap .delete {
  position: absolute;
  top: 52%;
  right: 0;
  transform: translateY(-50%);
}
.favorites-slice .wrap .delete button {
  right: 2px;
}
.favorites-slice .wrap .delete svg {
  font-size: 20px;
  color: #c1272d;
}
.favorites-slice .wrap .MuiListItemSecondaryAction-root {
  right: 8px;
}
.favorites-slice .wrap hr {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}
.favorites-slice .wrap li {
  padding: 0;
}
.favorites-slice .wrap li .MuiListItem-root {
  padding: 16px 0 16px 2px;
}
.favorites-slice .wrap li svg {
  fill: #015dac;
  transform: translateY(2px);
}
.favorites-slice .wrap li h1,
.favorites-slice .wrap li h2,
.favorites-slice .wrap li h3,
.favorites-slice .wrap li h4,
.favorites-slice .wrap li h5,
.favorites-slice .wrap li h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.annual-clock-slice {
  padding: 16px;
  overflow-x: scroll;
}
.annual-clock-slice .info {
  display: inline-block;
  padding: 4px;
}
.annual-clock-slice .info p {
  margin: 0;
  position: relative;
  padding-left: 16px;
}
.annual-clock-slice .info p.recommended-info:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #61bf1a;
  transform: translateY(-50%);
}
.annual-clock-slice .info p.demand-info:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #015dac;
  transform: translateY(-50%);
}
.annual-clock-slice .recommended {
  position: relative;
}
.annual-clock-slice .recommended:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #61bf1a;
  transform: translateY(-50%) translateX(-50%);
}
.annual-clock-slice .demand {
  position: relative;
}
.annual-clock-slice .demand:after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #015dac;
  transform: translateY(-50%) translateX(-50%);
}
.annual-clock-slice table thead tr th,
.annual-clock-slice table thead tr td {
  text-align: left;
  padding: 4px;
}
.annual-clock-slice table thead tr th.month p,
.annual-clock-slice table thead tr td.month p {
  text-align: center;
  font-weight: bold;
}
.annual-clock-slice table thead tr th.link,
.annual-clock-slice table thead tr td.link {
  padding-right: 16px;
}
.annual-clock-slice table thead tr th p,
.annual-clock-slice table thead tr th a,
.annual-clock-slice table thead tr td p,
.annual-clock-slice table thead tr td a {
  margin: 0;
  font-size: 16px;
}

.html-slice {
  margin-bottom: 16px;
}
.html-slice table {
  width: 100%;
}
.html-slice table tbody tr {
  margin-bottom: 8px;
}
.html-slice table tbody tr:last-child {
  margin-bottom: 0;
}
.html-slice table tbody tr th {
  font-weight: 600;
  font-size: 16px;
  padding: 0 16px;
  text-align: left;
}
.html-slice table tbody tr th:first-child {
  padding-left: 0;
}
.html-slice table tbody tr th:last-child {
  padding-right: 0;
}
.html-slice table tbody tr td {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  padding: 0 16px;
}
.html-slice table tbody tr td:first-child {
  padding-left: 0;
}
.html-slice table tbody tr td:last-child {
  padding-right: 0;
}

.login {
  width: 100%;
  height: 100%;
}
.login .login-wrap {
  display: flex;
  position: relative;
  flex: 1;
  float: none;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 32px;
  padding-top: 32px;
  min-height: 100%;
  width: 100%;
  background-color: #e9e8e7;
}
.login .login-wrap .version-number {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  color: #b3b1b2;
  font-size: 12px;
}
.login .login-wrap .login-box {
  padding: 32px 16px;
  width: 380px;
  max-width: calc(100% - 32px);
  border-radius: 9px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin-left: auto;
  margin-right: auto;
}
.login .login-wrap .login-box .logo-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.login .login-wrap .login-box .logo-wrap-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0 0px 0;
}
.login .login-wrap .login-box .title-wrap {
  margin-top: 0;
  text-align: center;
  margin-bottom: 32px;
}
.login .login-wrap .login-box .title-wrap.no-margin {
  margin-bottom: 0;
}
.login .login-wrap .login-box .title-wrap h1,
.login .login-wrap .login-box .title-wrap h2,
.login .login-wrap .login-box .title-wrap h3,
.login .login-wrap .login-box .title-wrap h4,
.login .login-wrap .login-box .title-wrap h5,
.login .login-wrap .login-box .title-wrap h6 {
  margin-bottom: 0;
}
.login .login-wrap .login-box .title-wrap p {
  margin-top: 16px;
  margin-bottom: 0;
}
.login .login-wrap .login-box button[type=submit] {
  margin-top: 32px;
}
.login .login-wrap .login-box .backbutton {
  margin-bottom: 32px;
}
.login .login-wrap .login-box .register-text {
  text-align: center;
  margin-top: 32px;
}
.login .login-wrap .login-box .register-text p {
  margin-bottom: 0;
}
.login .login-wrap .login-box .register-text button,
.login .login-wrap .login-box .register-text a {
  margin-top: 8px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
}

.button {
  position: relative;
  font-family: "Open Sans", sans-serif;
}
.button .button__inner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.button.link {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #09984a;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  position: relative;
  display: block;
  text-decoration: none;
  font-size: 16px;
}
.button.link:hover {
  color: #065f2e;
}
@media (min-width: 768px) {
  .button.link {
    font-size: 16px;
  }
}
.button.default {
  position: relative;
  border: 1px solid #09984a;
  background-color: #09984a;
  padding: 10px 32px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  font-size: 18px;
  transition: all 0.25s ease-in-out;
}
.button.default svg {
  position: absolute;
  left: 32px;
  transition: all 0.25s ease-in-out;
}
.button.default svg path {
  fill: #ffffff;
}
.button.default:hover:not(:disabled) {
  color: #ffffff;
  border-color: #065f2e;
  background-color: #065f2e;
}
.button.default:hover:not(:disabled) svg path {
  fill: #ffffff;
}
.button.default.small {
  font-size: 14px;
  padding: 8px 24px;
}
.button.default:disabled, .button.default.disabled {
  color: #ffffff;
  border: 1px solid #b3b1b2;
  background-color: #b3b1b2;
  cursor: not-allowed;
}
.button.default:disabled svg path, .button.default.disabled svg path {
  fill: #b3b1b2;
}
.button.default:disabled:hover, .button.default.disabled:hover {
  background-color: #b3b1b2 !important;
  color: #ffffff !important;
  border: 1px solid #b3b1b2 !important;
}
.button.full-width {
  width: 100%;
}
.button.hollow {
  position: relative;
  border: 1px solid #015dac;
  background-color: #ffffff;
  padding: 10px 32px;
  cursor: pointer;
  color: #015dac;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  font-size: 18px;
  transition: all 0.35s ease-in-out;
  font-size: 16px;
}
.button.hollow svg {
  position: absolute;
  left: 32px;
  transition: all 0.35s ease-in-out;
}
.button.hollow svg path {
  fill: #015dac;
}
.button.hollow:hover:not(:disabled) {
  color: #01447e;
  border-color: #01447e;
}
.button.hollow:hover:not(:disabled) svg path {
  fill: #01447e;
}
.button.hollow:disabled {
  color: #b3b1b2;
  border: 1px solid #b3b1b2;
  cursor: default;
}
.button.hollow:disabled svg path {
  fill: #b3b1b2;
}
.button.option {
  width: 100%;
}
.button.align-center {
  margin-left: auto;
  margin-right: auto;
}
.button.transparent {
  background-color: transparent;
  border: none;
  padding: 10px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.35s ease-in-out;
  font-weight: 600;
}
.button.transparent:hover {
  color: #01447e;
}
.button.transparent:hover svg {
  transform: translateY(-1px) translateX(-16px);
}
.button.transparent:hover svg path {
  fill: #01447e;
}
.button.transparent.small {
  font-size: 14px;
  padding: 8px 24px;
}
.button.transparent svg {
  transform: translateY(-1px) translateX(-8px);
  transition: all 0.35s ease-in-out;
}
.button.transparent.language {
  font-weight: normal;
}
.button.transparent.language:disabled {
  font-weight: 600;
  cursor: default;
}

.MuiIconButton-root.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.form-row {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: flex-end;
}
.form-row.result {
  background-color: #e9e8e7;
  padding: 16px;
}
.form-row.result .form-group {
  flex: 1;
}
.form-row.result .form-group:first-child:last-child {
  margin: 0 !important;
}
.form-row.result .form-label {
  font-weight: 600;
}
.form-row.result p {
  font-size: 20px;
  font-weight: 600;
  color: #09984a !important;
  margin: 0;
}
@media (min-width: 768px) {
  .form-row.result p {
    font-size: 24px;
  }
}
.form-row .form-checkbox {
  margin-bottom: 8px;
}
.form-row .form-checkbox:first-of-type {
  margin-right: 16px;
}
.form-row .form-checkbox:last-of-type {
  margin-left: 16px;
}
.form-row .form-group {
  width: 100%;
  margin-bottom: 0;
}
.form-row .form-group:first-of-type {
  margin-right: 16px;
}
.form-row .form-group:last-of-type {
  margin-left: 16px;
}

.clear {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: 4px;
  z-index: 11;
  margin: 0 !important;
  cursor: pointer;
  user-select: none;
}

.eye {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 42px;
  bottom: 8px;
  z-index: 11;
  margin: 0 !important;
  cursor: pointer;
  user-select: none;
}

.form-group {
  position: relative;
  margin-bottom: 16px;
}
.form-group input::-webkit-outer-spin-button,
.form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-group.rounded .form-label + .form-input {
  margin-top: 8px !important;
}
.form-group.rounded .form-input {
  border: 1px solid #93c01f;
  border-radius: 9px;
  position: relative;
  padding: 14px 36px 14px 8px;
}
.form-group.rounded .form-input.counter-input {
  padding-right: 14px;
  margin-top: 8px !important;
  width: calc(100% - 64px);
}
.form-group.rounded .eye {
  bottom: 11px;
}
.form-group.rounded .clear {
  bottom: 8px;
  right: 4px;
}
.form-group.rounded button {
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  border-radius: 100%;
  transition-duration: 150ms;
  transform: translateY(3px);
}
.form-group.rounded button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.form-input-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

select {
  cursor: pointer;
}

.form-input {
  border: none;
  border-bottom: 1px solid #61bf1a;
  margin: 0;
  padding: 12px 0 8px 0;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  padding-right: 22px;
  background-color: transparent;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.form-input:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}
.form-input::placeholder {
  color: #000000;
}
.form-input.password {
  padding-right: 68px !important;
}

.select-icon {
  position: absolute;
  bottom: 12px;
  right: 16px;
}

.form-label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  user-select: none;
  position: relative;
  display: block;
}

.form-checkbox {
  position: relative;
  display: flex;
  margin-bottom: 16px;
}
.form-checkbox label {
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  padding-left: 28px;
  z-index: 111;
}
.form-checkbox input {
  position: absolute;
  left: 0;
  margin: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  cursor: pointer;
}
.form-checkbox input[type=radio] + span:before {
  border-radius: 100%;
}
.form-checkbox input + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid #61bf1a;
  border-radius: 4px;
  background-color: #ffffff;
}
.form-checkbox input + span:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 9px;
  left: 7px;
  top: calc(50% - 2px);
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}
.form-checkbox input:checked + span:before {
  background-color: #09984a;
}

.counter-slider {
  height: 100%;
}
.counter-slider .MuiSlider-root {
  color: #09984a !important;
}
.counter-slider .MuiSlider-root .MuiSlider-thumb.Mui-focusVisible,
.counter-slider .MuiSlider-root .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 8px #e9e8e7;
}
.counter-slider .MuiSlider-root .MuiSlider-track {
  height: 3.4px !important;
}
.counter-slider .MuiSlider-root .MuiSlider-rail {
  height: 2.4px !important;
}
.counter-slider .MuiSlider-root .MuiSlider-thumb {
  height: 15.65px !important;
  width: 15.65px !important;
  margin-top: -7px;
}
.counter-slider .slider-wrap {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.counter-slider .slider-wrap .slider-value {
  padding: 14px 16px 14px 0px;
  margin: 0;
}

.MuiTabs-root {
  margin-bottom: 16px;
}
.MuiTabs-root .MuiTab-root {
  transition: background-color 0.15s ease-in-out;
}
.MuiTabs-root .MuiTab-root:hover {
  background-color: #e9e8e7;
}
.MuiTabs-root .MuiTab-root .MuiTab-wrapper {
  font-weight: 600;
  text-transform: none;
  color: #000000;
  font-size: 16px;
}
.MuiTabs-root .MuiTab-root.Mui-selected .MuiTab-wrapper {
  color: #09984a;
}
.MuiTabs-root .MuiTabs-indicator {
  background-color: #93c01f;
}

.page {
  background-color: #ffffff;
  min-height: calc(100% - 56px);
  padding-bottom: 16px;
}
.page .page-title {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 16px 0 16px;
  position: relative;
  margin-bottom: 32px;
}
.page .page-title.no-padding {
  padding: 0;
}
.page .page-title.no-padding:after {
  content: "";
  height: 1px;
  width: 100%;
  left: 0;
  bottom: -16px;
}
.page .page-title h1,
.page .page-title h2,
.page .page-title h3,
.page .page-title h4,
.page .page-title h5,
.page .page-title h6 {
  font-size: 18px;
  margin: 0;
}
@media (min-width: 768px) {
  .page .page-title h1,
.page .page-title h2,
.page .page-title h3,
.page .page-title h4,
.page .page-title h5,
.page .page-title h6 {
    font-size: 18px;
  }
}
.page .page-title:after {
  content: "";
  height: 1px;
  width: calc(100% - 32px);
  left: 16px;
  bottom: -16px;
  background-color: #61bf1a;
  position: absolute;
}

.backbutton {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}
.backbutton .wrap {
  display: inline-block;
}
.backbutton.no-wrap {
  max-width: unset;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
}
.backbutton.no-wrap a,
.backbutton.no-wrap button {
  margin: 0;
}
.backbutton a,
.backbutton button {
  font-size: 16px;
  text-decoration: none !important;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline;
}
.backbutton a:hover div,
.backbutton button:hover div {
  background-color: rgba(0, 0, 0, 0.04);
}
.backbutton a div,
.backbutton button div {
  transition-duration: 150ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px 2px 0;
}
.backbutton a span,
.backbutton button span {
  margin-left: 8px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  color: #000000;
}

.search .input-wrap {
  padding: 16px 16px 0 16px;
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.search .results-count {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 16px 0 16px;
  position: relative;
  margin-bottom: 32px;
}
.search .results-count h1 {
  font-size: 18px;
  margin: 0;
}
@media (min-width: 768px) {
  .search .results-count h1 {
    font-size: 18px;
  }
}
.search .results-count:after {
  content: "";
  height: 1px;
  width: calc(100% - 32px);
  left: 16px;
  bottom: -16px;
  background-color: #61bf1a;
  position: absolute;
}

.toast {
  position: fixed;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%) translateY(8px);
  bottom: 16px;
  z-index: 11111;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  width: calc(100% - 32px);
  max-width: 768px;
}
.toast.is-active {
  opacity: 1;
  pointer-events: all;
  transform: translateX(-50%) translateY(0px);
}
.toast .wrap {
  border-radius: 9px;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 16px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
}
.toast .wrap .content {
  width: 100%;
  padding-top: 16px;
  padding-right: 8px;
}
.toast .wrap .close {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.toast .wrap .close button {
  padding: 8px;
  margin: 8px 8px 8px 16px;
}
.toast .wrap.red {
  background-color: #c1272d;
}
.toast .wrap.red h1,
.toast .wrap.red h2,
.toast .wrap.red h3,
.toast .wrap.red h4,
.toast .wrap.red h5,
.toast .wrap.red h6,
.toast .wrap.red p,
.toast .wrap.red a,
.toast .wrap.red li {
  color: #ffffff;
}
.toast .wrap.green {
  background-color: #09984a;
}
.toast .wrap.green h1,
.toast .wrap.green h2,
.toast .wrap.green h3,
.toast .wrap.green h4,
.toast .wrap.green h5,
.toast .wrap.green h6,
.toast .wrap.green p,
.toast .wrap.green a,
.toast .wrap.green li {
  color: #ffffff;
}
.toast .wrap.orange {
  background-color: #e58b27;
}
.toast .wrap.orange h1,
.toast .wrap.orange h2,
.toast .wrap.orange h3,
.toast .wrap.orange h4,
.toast .wrap.orange h5,
.toast .wrap.orange h6,
.toast .wrap.orange p,
.toast .wrap.orange a,
.toast .wrap.orange li {
  color: #ffffff;
}
.toast .wrap h1,
.toast .wrap h2,
.toast .wrap h3,
.toast .wrap h4,
.toast .wrap h5,
.toast .wrap h6,
.toast .wrap p,
.toast .wrap a,
.toast .wrap li {
  margin: 0;
  padding-bottom: 16px;
}

.chart {
  position: relative;
  padding-top: 32px;
}
.chart .stamplimit-alert {
  position: absolute;
  top: 0;
  margin: 0;
}
.chart .legend {
  display: flex;
  flex-flow: row wrap;
  margin-left: 32px;
  margin-top: 16px;
  justify-content: center;
}
.chart .legend p {
  margin-right: 32px;
  position: relative;
  font-size: 12px;
  margin-top: 0;
}
.chart .legend p:last-of-type {
  margin-right: 0;
}
.chart .legend p:before {
  content: "";
  position: absolute;
  width: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: -22px;
}
.chart .legend p.stamplimit:before {
  border-bottom: 3px dashed brown;
}
.chart .legend p.cutting-after-target:before {
  border-bottom: 3px solid #09984a;
}
.chart .legend p.cutting-after-limit:before {
  border-bottom: 3px solid #93c01f;
}
.chart .legend p.ceiling:before {
  border-bottom: 3px solid #c1272d;
}
.chart .legend p.ceiling-es:before {
  border-bottom: 3px solid #93278f;
}
@media (min-width: 768px) {
  .chart canvas {
    cursor: pointer;
  }
}

.CookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1111111111;
  background-color: #ffffff;
  border-top: 2px solid #09984a;
  padding-bottom: 16px;
}
.CookieConsent div:first-of-type {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}
.CookieConsent div:last-of-type {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.CookieConsent button {
  display: inline-block !important;
}

